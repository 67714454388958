import React from "react"
import Layout from "../components/layout.js"
import Nav from "../components/nav.js"
import SEO from "../components/seo.js"
import "./veranstaltungen.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Veranstaltungen" />
    <Nav />
    <p className="ueberschrift">Alle Veranstaltungen</p>
  </Layout>
)

export default IndexPage
